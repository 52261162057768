<template>
  <!-- We need this double div with the toggle so video js can properly change the video if the source changes. This is for example the case when we have 2 videos in the PDP stage element and switch between them. -->
  <div v-if="toggle" class="h-full">
    <video ref="videoPlayer" class="video-js"></video>
  </div>
  <div v-else class="h-full">
    <video ref="videoPlayer" class="video-js"></video>
  </div>
</template>

<script setup lang="ts">
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import type Player from 'video.js/dist/types/player'
import { falsy } from '~/helpers/type'

// Could not find a proper type for this in video.js >= 8 and @types/video.js is only for <= 7 https://github.com/DefinitelyTyped/DefinitelyTyped/discussions/65641#discussioncomment-6116045
interface VideoJsPlayerOptions {
  autoplay?: boolean
  bigPlayButton?: boolean
  controls?: boolean
  fill?: boolean
  loop?: boolean
  poster?: string
  sources: {
    src: string
    type?: string
  }[]
  muted?: boolean
  controlBar?: {
    volumePanel?: boolean
  }
  playsinline?: boolean
}

export type MediaVideoProps = {
  videoClass?: string
} & VideoJsPlayerOptions

const props = withDefaults(defineProps<MediaVideoProps>(), {
  // playsinline is needed that the video does not pop to fullscreen automatically on mobile phones. Tested on iPhone.
  playsinline: true,
  bigPlayButton: false,
  autoplay: false,
  preload: 'metadata',
})

const videoPlayer = ref<HTMLVideoElement>()
const player = ref<Player>()
const toggle = ref(false)

watch(props, () => {
  // player.value.options(newProps) does not work. We need to set the options manually.
  player.value?.controls(props.controls)

  if (player.value?.poster() !== props.poster) {
    player.value?.loadMedia(
      {
        poster: props.poster,
      },
      () => {},
    )
  }

  player.value?.muted(props.muted)
})

const intersectionObserver = ref<IntersectionObserver>()

const resetPlayer = () => {
  intersectionObserver.value?.disconnect()
  player.value?.dispose()
  toggle.value = !toggle.value

  nextTick(() => {
    if (videoPlayer.value) {
      const playerOptions: VideoJsPlayerOptions = {
        ...props,
        // set autoplay always to false. We do not want to play the video if it is not in the viewport. With the intersectionObserver with programmatically press play and pause if autoplay is set to true.
        autoplay: false,
      }
      player.value = videojs(
        videoPlayer.value,
        playerOptions,
        function (this: Player) {
          const playerInstance = this.player()

          // Add the videoClass to the video element
          const classes = props.videoClass?.split(' ').filter(falsy) ?? []
          if (classes.length > 0) {
            this.player()
              // See https://github.com/videojs/video.js/issues/2617
              .tech({ IWillNotUseThisInPlugins: true })
              .el()
              .classList.add(...classes)
          }

          // on iOS Safari intersectionObserver does not seem to work. We therefore load the video instantly there.
          // from https://stackoverflow.com/a/58065241
          const isIOS =
            /iPad|iPhone|iPod/.test(navigator.platform) ||
            (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)

          if (isIOS && props.autoplay) {
            playerInstance.autoplay('play')
          }

          if (!isIOS) {
            // autoplay only when the video is inside the viewport.
            intersectionObserver.value = new IntersectionObserver((entries) => {
              entries.forEach((entry) => {
                if (!props.autoplay) {
                  return
                }

                if (entry.isIntersecting) {
                  return playerInstance.play()
                }

                if (playerInstance && playerInstance.muted()) {
                  return playerInstance.pause()
                }
              })
            })

            intersectionObserver.value.observe(this.el())
          }
        },
      )
    }
  })
}

watch(
  () => props.sources,
  () => {
    if (import.meta.client) {
      // Only reset the player, when props.sources changes on the client.
      // Otherwise nuxt crashes in SSR context.
      // We reset the player also in onMounted() anyways.
      resetPlayer()
    }
  },
)

const play = () => {
  player.value?.play()
}

defineExpose({
  play,
})

onMounted(() => {
  resetPlayer()
})

onUnmounted(() => {
  intersectionObserver.value?.disconnect()
  player.value?.dispose()
})
</script>

<style scoped lang="scss">
.video-js {
  @apply bg-grey-light-01;
  width: 100%;
  height: 100%;
}

:deep(.vjs-poster img) {
  object-fit: cover;
}
</style>
